<template>
  <div>
    <span
      v-if="isBool"
      class="tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-justify-between mb-1"
        @click="handleToggle(field.key)"
      >
        {{ field.label }}
        <feather-icon
          :icon="collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-mr-3"
        />
      </div>
      <div :class="collapse === field.key ? '' : 'd-none'">
        <b-form-group>
          <b-form-radio
            v-model="form.radioGroup"
            value="equalsTo"
            class="mb-1"
          >
            {{ $t('Equals to') }}
          </b-form-radio>
        </b-form-group>
        <b-form-checkbox
          v-model="nativeValue"
          switch
          inline
        >
          <span style="font-size: 1rem">{{ $t($case.title(field.label)) }}</span>
        </b-form-checkbox>
      </div>
    </span>
    <span
      v-else-if="isTypeNumeric"
      class="tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-justify-between mb-1"
        @click="handleToggle(field.key)"
      >
        {{ field.label }}
        <feather-icon
          :icon="collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-mr-3"
        />
      </div>
      <div :class="collapse === field.key ? '' : 'd-none'">
        <prozess-field-wrapper class="flex mt-2">
          <prozess-select
            v-model="form.select"
            style="flex: 1"
            :placeholder="$t('Select Relationship')"
            :options="inequalityOptions"
            :reduce="option => option.key"
            :searchable="false"
          />
        </prozess-field-wrapper>
        <div class="d-flex">
          <prozess-input
            v-model.number="nativeValue"
            type="number"
            :bind="{ ...$attrs }"
            :disabled="$attrs.disabled"
            :placeholder="$attrs.placeholder || $t($case.title(field.label))"
            :icon="customFieldTypeIcons[field.type]"
            :error="error"
            style="flex: 1"
            @enter="$emit('enter')"
            @wheel="$event.target.blur()"
          />
          <prozess-input
            v-if="form.select === 'range'"
            v-model.number="form.range"
            type="number"
            :bind="{ ...$attrs }"
            :disabled="$attrs.disabled"
            :placeholder="$attrs.placeholder || $t($case.title(field.label))"
            :icon="customFieldTypeIcons[field.type]"
            :error="error"
            style="flex: 1"
            @enter="$emit('enter')"
            @wheel="$event.target.blur()"
          />
          <!-- <b-input v-if="form.select === 'range'" v-model="form.range" type="number" class="w-100 pl-1" /> -->
        </div>
      </div>
    </span>

    <!-- <div v-else-if="field.type === CustomFieldTypes.Bool" class="my-1">
      <b-form-checkbox
        v-model="nativeValue"
        switch
        :disabled="$attrs.disabled"
        inline
        @keyup.enter.native="$emit('enter')"
      >
        <span style="font-size: 1rem">{{ $t($case.title(field.label)) }}</span>
      </b-form-checkbox>
      <span v-if="error" class="invalid-feedback">
        {{ error }}
      </span>
    </div> -->
    <span
      v-else-if="isDate"
      class="tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-justify-between mb-1"
        @click="handleToggle(field.key)"
      >
        {{ field.label }}
        <feather-icon
          :icon="collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-mr-3"
        />
      </div>
      <div :class="collapse === field.key ? '' : 'd-none'">
        <prozess-field-wrapper class="flex mt-2">
          <prozess-select
            v-model="form.select"
            style="flex: 1"
            :placeholder="$t('Select Relationship')"
            :options="inequalityOptions"
            :reduce="option => option.key"
            :searchable="false"
          />
        </prozess-field-wrapper>
        <date-range-filter
          v-if="form.select === 'range'"
          v-model="nativeValue"
          class="w-100"
          :placeholder="$t('Date range')"
        />
        <prozess-date-picker
          v-else
          v-model="nativeValue"
          :is-birth-date="field.key === 'birth_date'"
          :disabled="$attrs.disabled"
          :locale="$i18n.locale"
          :placeholder="$attrs.placeholder || $t($case.title(field.label))"
          @keyup.enter.native="$emit('enter')"
        />
      </div>
    </span>

    <!-- <prozess-field-wrapper
      v-else-if="field.type === CustomFieldTypes.Date"
      :error="error"
    >
      <b-form-datepicker
        v-model="nativeValue"
        :disabled="$attrs.disabled"
        :locale="$i18n.locale"
        :placeholder="$attrs.placeholder || $t($case.title(field.label))"
        @keyup.enter.native="$emit('enter')"
      />

      <date-range-filter
        v-if="form.select === 'range'"
        v-model="nativeValue"
        class="w-100"
        :placeholder="$t('Date range')"
      />
      <prozess-date-picker
        v-else
        v-model="nativeValue"
        :is-birth-date="(field.key === 'birth_date')"
        :disabled="$attrs.disabled"
        :locale="$i18n.locale"
        :placeholder="$attrs.placeholder || $t($case.title(field.label))"
        @keyup.enter.native="$emit('enter')"
      />

    </prozess-field-wrapper> -->
    <span
      v-else-if="isTypeListLike"
      class="tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-justify-between mb-1"
        @click="handleToggle(field.key)"
      >
        {{ field.label }}
        <feather-icon
          :icon="collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-mr-3"
        />
      </div>
      <div :class="collapse === field.key ? '' : 'd-none'">
        <prozess-field-wrapper
          :icon="customFieldTypeIcons[field.type]"
          :error="error"
          :disabled="$attrs.disabled"
          class="tw-flex-1 tw-pt-3"
        >
          <prozess-select
            v-model="nativeValue"
            :disabled="$attrs.disabled"
            class="tw-flex-1"
            :multiple="isMultiSelect"
            :placeholder="$t($case.title(field.label))"
            :options="field.options"
            :label="$t($case.title(field.label))"
            @keyup.enter.native="$emit('enter')"
          />
        </prozess-field-wrapper>
      </div>
    </span>
    <!-- <prozess-field-wrapper
      v-else-if="isTypeListLike"
      :icon="customFieldTypeIcons[field.type]"
      :error="error"
      :disabled="$attrs.disabled"
      class="tw-flex-1 tw-pt-3"
    >
      <prozess-select
        v-model="nativeValue"
        :disabled="$attrs.disabled"
        class="tw-flex-1"
        :multiple="isMultiSelect"
        :placeholder="$t($case.title(field.label))"
        :options="field.values"
        :label="$t($case.title(field.label))"
        @keyup.enter.native="$emit('enter')"
      />
    </prozess-field-wrapper> -->
    <span
      v-else
      class="tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-justify-between mb-1"
        @click="handleToggle(field.key)"
      >
        {{ field.label }}
        <feather-icon
          :icon="collapse ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="tw-mr-3"
        />
      </div>
      <div :class="collapse === field.key ? '' : 'd-none'">
        <b-form-group>
          <b-form-radio
            v-model="form.radioGroup"
            value="equalsTo"
            class="mb-1"
          >
            {{ $t('Equals to') }}
          </b-form-radio>
          <b-form-radio
            v-model="form.radioGroup"
            value="contains"
          >
            {{ $t('Contains') }}
          </b-form-radio>
        </b-form-group>
        <prozess-input
          v-model="nativeValue"
          :bind="{ ...$attrs }"
          :disabled="$attrs.disabled"
          :placeholder="$attrs.placeholder || $t($case.title(field.label))"
          :icon="customFieldTypeIcons[field.type]"
          :error="error"
          @enter="$emit('enter')"
        />
      </div>
    </span>

    <hr
      class="tw-my-6"
      style="height: 1px; background-color: gray !important"
    />
  </div>
</template>

<script>
import CustomFieldTypes, { customFieldTypeIcons } from '@/constants/customFieldTypes'
import requiredValidatorMixin from '@/mixins/requiredValidator'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import { inequalityOptions } from '@/constants/report'

export default {
  components: {
    DateRangeFilter,
  },
  mixins: [requiredValidatorMixin],
  props: {
    error: {
      type: String,
      default: null,
    },

    field: {
      type: Object,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    value: {
      type: [String, Number, Date, Boolean, Array, Object],
      default: null,
    },
  },

  data() {
    return {
      customFieldTypeIcons,
      nativeValue: '',
      CustomFieldTypes,
      collapse: false,
      form: {},
      inequalityOptions: this.$lodash.cloneDeep(inequalityOptions),
    }
  },

  computed: {
    isTypeNumeric() {
      return [CustomFieldTypes.Number, CustomFieldTypes.Percent, CustomFieldTypes.Phone].includes(this.field.type)
    },

    isTypeListLike() {
      return [CustomFieldTypes.List, CustomFieldTypes.MultiSelect].includes(this.field.type)
    },

    isMultiSelect() {
      return this.field.type === CustomFieldTypes.MultiSelect
    },

    isPercent() {
      return this.field.type === CustomFieldTypes.Percent
    },

    isEmail() {
      return this.field.type === CustomFieldTypes.Email
    },

    isBool() {
      return this.field.type === 'BOOLEAN'
    },

    isURL() {
      return this.field.type === CustomFieldTypes.Url
    },
    isDate() {
      return this.field.type === CustomFieldTypes.Date
    },
  },
  watch: {
    value: {
      handler(value) {
        this.nativeValue = this.isPercent ? this.$round10(+value * 100, -4) : value
      },

      immediate: true,
    },

    nativeValue: {
      handler(value) {
        let data = this.isPercent ? value / 100 : value

        if (this.isTypeNumeric) {
          data = !data && data !== 0 ? null : data
        } else if (this.isEmail || this.isURL) {
          data = data || null
        } else if (this.isMultiSelect) {
          data = data && data.length ? data : null
        }

        this.$emit('input', data)
      },
    },
  },
  methods: {
    handleToggle(key) {
      this.collapse = this.collapse === key ? false : key
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  padding: 0;
}
</style>
