export const aggregationType = [
  {
    label: 'Min',
    key: 'MIN',
  },
  {
    label: 'Max',
    key: 'MAX',
  },
  {
    label: 'Sum',
    key: 'SUM',
  },
  {
    label: 'Average',
    key: 'AVG',
  },
]

export const inequalityOptions = [
  {
    label: 'Equals To',
    key: 'equalsTo',
  },
  {
    label: 'Greater than',
    key: 'greaterThan',
  },
  {
    label: 'Greater than or equal',
    key: 'greaterThanOrEqual',
  },
  {
    label: 'Less than',
    key: 'lessThan',
  },
  {
    label: 'Less than or equal',
    key: 'lessThanOrEqual',
  },
  {
    label: 'Range',
    key: 'range',
  },
]
