<template>
  <div style="width: 100%">
    <div
      class="tw-flex tw-cursor-pointer tw-py-3 tw-pl-3 tw-text-text-primary groupings"
      @click="handleToggle"
    >
      <feather-icon
        :icon="collapse ? 'ChevronDownIcon' : 'ChevronRightIcon'"
        class="tw-mr-3"
      />
      <div class="tw-w-full">
        <div class="tw-flex tw-items-center tw-justify-between">
          <span class="tw-flex tw-items-center">
            <span>
              {{ $t('Filters') }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="collapse"
      class="w-100 groupings_content"
    >
      <div
        v-for="item in columns"
        :key="item.key"
      >
        <FilterFields
          v-model="form[item.key]"
          :field="item"
        />
        <!-- <AppDynamicField
          v-model="form[item.key]"
          :field="item"
          :error="$hasError(item.key)"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import FilterFields from './FilterFields.vue'

export default {
  components: {
    FilterFields,
  },

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      collapse: false,
      form: '',
    }
  },

  methods: {
    handleToggle() {
      this.collapse = !this.collapse
    },
    handleSelectedColumn(item) {
      this.selectedColumn = item.key
    },
  },
}
</script>
<style scoped>
.groupings {
  border: 1px solid var(--colour--input-border) !important;
  border-radius: 4px;
}

.groupings_content {
  position: absolute;
  padding: 10px 20px;
  background: var(--colour--body-bg);
}
</style>
