<template>
  <div style="width: 100%">
    <div
      class="tw-flex tw-cursor-pointer tw-py-3 tw-pl-3 tw-text-text-primary groupings"
      @click="handleToggle"
    >
      <feather-icon
        :icon="collapse ? 'ChevronDownIcon' : 'ChevronRightIcon'"
        class="tw-mr-3"
      />
      <div class="tw-w-full">
        <div class="tw-flex tw-items-center tw-justify-between">
          <span class="tw-flex tw-items-center">
            <span>
              {{ $t('Groupings/Summarising') }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div v-if="collapse" class="w-100 shadow-sm groupings_content">
      <b-form-group>
        <b-form-radio
          v-model="aggregation"
          name="group-by"
          value="group"
          class="mb-1"
        >
          {{ $t('Group By') }}
        </b-form-radio>
        <b-form-radio
          v-model="aggregation"
          name="summarize-by"
          value="summarize"
        >
          {{ $t('Summarize By') }}
        </b-form-radio>
      </b-form-group>
      <hr
        class="tw-my-6"
        style="height: 1px; background-color: gray !important"
      >
      <b-form-group>
        <b-form-radio
          v-model="aggregationType"
          name="aggregationType"
          value="MIN"
          class="mb-1"
        >
          {{ $t('Min') }}
        </b-form-radio>
        <b-form-radio
          v-model="aggregationType"
          name="aggregationType"
          value="MAX"
          class="mb-1"
        >
          {{ $t('Max') }}
        </b-form-radio>
        <b-form-radio
          v-model="aggregationType"
          name="aggregationType"
          value="SUM"
          class="mb-1"
        >
          {{ $t('Sum') }}
        </b-form-radio>
        <b-form-radio
          v-model="aggregationType"
          name="aggregationType"
          value="AVE"
        >
          {{ $t('Average') }}
        </b-form-radio>
      </b-form-group>
      <hr
        class="tw-my-6"
        style="height: 1px; background-color: gray !important"
      >
      <!-- TODO:: Add none to selectedColumns and make it default-->
      <div v-for="item in selectedColumns" :key="item.key" class="tw-p-2">
        <span
          class="tw-cursor-pointer"
          :class="{ 'tw-text-primary': selectedColumn === item.key }"
          @click="handleSelectedColumn(item)"
        >
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      aggregation: '',
      collapse: false,
      selectedColumn: [...this.selectedColumns, { label: 'None', key: 'none' }],
      aggregationType: '',
    }
  },

  methods: {
    handleToggle() {
      this.collapse = !this.collapse
    },
    handleSelectedColumn(item) {
      this.selectedColumn = item.key
    },
  },
}
</script>
<style scoped>
.groupings {
  border: 1px solid var(--colour--input-border) !important;
  border-radius: 4px;
}

.groupings_content {
  position: absolute;
  padding: 10px 20px;
  background: var(--colour--body-bg);
  box-shadow: 1px;
}
</style>
