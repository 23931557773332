<template>
  <div id="page__reports-view">
    <ReportSidebar
      v-if="sidebarReady"
      :visible="sidebarOpen"
      :primary-filter="primaryFilter"
      :column-options="columnOptions"
      @close="sidebarOpen = false"
    />
    <b-card id="page--reports" no-body>
      <div class="card-body tw-flex">
        <prozess-field-wrapper class="tw-flex-1 tw-pt-3 mr-1">
          <date-range-filter
            v-model="date"
            :placeholder="$t('Date range')"
          />
        </prozess-field-wrapper>

        <prozess-field-wrapper class="tw-flex-1 tw-pt-3 mr-1">
          <prozess-select
            v-model="selectedColumns"
            multiple
            :placeholder="$t('Columns')"
            :options="columnOptions"
            class="filter__columns tw-flex-1"
            @selecting="handleSelecting"
          >
            <template v-if="selectedColumns.length" #selected-option>
              {{ selectedColumns.length }} {{ $t('column(s) selected') }}
            </template>
            <template v-else #selected-option>
              {{ $t('Select Columns') }}
            </template>
          </prozess-select>
        </prozess-field-wrapper>

        <prozess-field-wrapper class="tw-flex-1 tw-pt-3 mr-1">
          <ReportGroupings :selected-columns="selectedColumns" />
        </prozess-field-wrapper>

        <prozess-field-wrapper class="tw-flex-1 tw-pt-3 mr-1">
          <ReportFilters :columns="columns" />
        </prozess-field-wrapper>

        <prozess-field-wrapper class="tw-pt-3 mr-1 tw-justify-end">
          <b-button
            class="ml-2"
            variant="outline-primary"
          >
            {{ $t('Export') }}
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
          >
            {{ $t('Generate') }}
          </b-button>
        </prozess-field-wrapper>
      </div>
      <hr>
      <div class="card-body tw-flex tw-justify-end">
        <prozess-field-wrapper class="mr-1">
          <b-button
            class="ml-2 w-full"
            variant="outline-primary"
          >
            <feather-icon icon="ColumnsIcon" class="tw-mr-3 text-primary" />
            <span class="align-middle">{{ $t('Reorder Columns') }}</span>
          </b-button>
        </prozess-field-wrapper>
      </div>

      <div v-if="!data.length" class="tw-flex card-body tw-my-4 justify-content-center">
        <span> {{ $t('No matching records found') }} </span>
      </div>

    </b-card>
  </div>
</template>
<script>
import reportService from '@/services/report'
import RootEvents from '@/constants/rootEvents'
import CustomFieldTypes from '@/constants/customFieldTypes'
import ReportSidebar from '@/views/pages/Reports/ReportSidebar.vue'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import ReportGroupings from './ReportGroupings.vue'
import ReportFilters from './ReportFilters.vue'

export default {
  components: {
    ReportSidebar,
    DateRangeFilter,
    ReportGroupings,
    ReportFilters,
  },

  data() {
    return {
      columns: [],
      columnOptions: [],
      filters: [],
      primaryFilter: null,
      resource: null,
      sidebarOpen: false,
      sidebarReady: false,
      selectedColumns: [],
      data: {},
      date: null,
    }
  },

  async mounted() {
    await this.getData()
    await this.getAllColumns()

    /**
     * This will start rendering the report sidebar.
     * We need to delay the rendering of the sidebar to
     * make sure that the columns are already loaded before we render the sidebar.
     */
    this.sidebarReady = true
    this.selectedColumns = this.$lodash.cloneDeep(this.columnOptions)
  },

  methods: {
    async getAllColumns() {
      const { response } = await this.$async(
        Promise.all([
          reportService.getAllColumns(this.$route.params.id),
          reportService.getAllFilters(this.$route.params.id),
        ]),
      )

      const [columnsRes, filtersRes] = response

      this.columns = columnsRes.data

      // Format response for the Columns Selector component
      this.columnOptions = this.columns.map(({ key, label }) => ({
        key,
        label,
      }))

      // Set filter types
      this.filters = filtersRes.data.reduce((acc, item) => {
        const column = this.columns.find(({ id }) => id === item.fieldId)

        if (column) {
          acc.push({
            ...item,
            type: column.type,
          })
        }

        return acc
      }, [])

      // Set the primary filter. Must be a DATE type.
      this.primaryFilter = this.filters.find(
        ({ type, primary }) => type === CustomFieldTypes.Date && primary,
      )
    },

    async getData() {
      const { response } = await this.$async(
        reportService.getOne(this.$route.params.id),
      )

      this.resource = response.data

      this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.resource.label)
      this.$root.$emit(RootEvents.UPDATE_BREADCRUMB_ITEM, {
        value: { text: 'Generate Report', active: true },
      })
    },

    handleSelecting(selection) {
      const lastSelectedColumns = this.$lodash.cloneDeep(this.selectedColumns)

      // Find index of the selection
      const index = lastSelectedColumns.findIndex(
        ({ key }) => key === selection.key,
      )

      // If the selection already exists, remove it from the selections to deselect it
      // `this.$nextTick` is to make sure the selection took effect already before doing the removal, do not remove it.
      this.$nextTick(() => {
        if (index >= 0) {
          this.selectedColumns.splice(index, 1)
        }
      })
    },
  },
}
</script>

<style lang="scss">
#page__reports-view {
  .vs__selected-options {
    width: 100%;

    .vs__selected {
      color: var(--colour--dropdown-item);

      &:not(:first-child) {
        display: none !important;
      }

      .vs__deselect {
        display: none !important;
      }
    }
  }
}
</style>
