<template>
  <prozess-sidebar-modal-wrapper
    id="report-sidebar"
    :visible="visible"
    :form-title="$t('Filter Report')"
    :loading="loading"
    :saving="saving"
    @close="$emit('close')"
  >
    <div class="p-2 sidebar__enclosure tw-flex-1">
      <div
        v-if="primaryFilter"
        class="mb-1"
      >
        <div>
          <label>{{ $t('Date') }}</label>
          <b-form-datepicker
            :locale="$i18n.locale"
            placeholder="Date"
          />
        </div>
      </div>

      <div class="mb-1">
        <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
          <prozess-select
            v-model="selectedColumns"
            class="filter__columns tw-flex-1"
            multiple
            :placeholder="$t('Select Columns')"
            :options="columnOptions"
            @selecting="handleSelecting"
          >
            <template
              v-if="selectedColumns.length"
              #selected-option
            >
              {{ selectedColumns.length }} {{ $t('column(s) selected') }}
            </template>
            <template
              v-else
              #selected-option
            >
              {{ $t('Select Columns') }}
            </template>
          </prozess-select>
        </prozess-field-wrapper>
      </div>

      <div class="mb-1">
        <label class="mb-1">{{ $t('Grouping / Summarization') }}</label>
        <b-form-group>
          <b-form-radio
            v-model="aggregation"
            name="group-by"
            value="group"
            class="mb-1"
          >
            {{ $t('Group By') }}
          </b-form-radio>
          <b-form-radio
            v-model="aggregation"
            name="summarize-by"
            value="summarize"
            class="mb-1"
          >
            {{ $t('Summarize By') }}
          </b-form-radio>
        </b-form-group>
      </div>
      <div class="mb-1">
        <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
          <prozess-select
            :placeholder="$t('Group on what field?')"
            :options="columnOptions"
            class="tw-flex-1"
          />
        </prozess-field-wrapper>
      </div>
      <div class="mb-1">
        <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
          <prozess-select
            :placeholder="$t('Summarize on what field?')"
            :options="columnOptions"
            class="tw-flex-1"
          />
        </prozess-field-wrapper>
      </div>
      <div class="mb-1">
        <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
          <prozess-select
            :placeholder="$t('Summarize on what field?')"
            :options="aggregationType"
            class="tw-flex-1"
          />
        </prozess-field-wrapper>
      </div>
    </div>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { aggregationType } from '@/constants/report'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    primaryFilter: {
      type: Object,
      default: null,
    },

    columnOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      selectedColumns: [],
      aggregation: null,
      aggregationType: this.$lodash.cloneDeep(aggregationType),
    }
  },

  mounted() {
    // Auto-Select all columnOptions
    this.selectedColumns = this.$lodash.cloneDeep(this.columnOptions)
  },

  methods: {
    handleSelecting(selection) {
      const lastSelectedColumns = this.$lodash.cloneDeep(this.selectedColumns)

      // Find index of the selection
      const index = lastSelectedColumns.findIndex(({ key }) => key === selection.key)

      // If the selection already exists, remove it from the selections to deselect it
      // `this.$nextTick` is to make sure the selection took effect already before doing the removal, do not remove it.
      this.$nextTick(() => {
        if (index >= 0) {
          this.selectedColumns.splice(index, 1)
        }
      })
    },
  },
}
</script>

<style lang="scss">
#report-sidebar {
  .vs__selected-options {
    width: 100%;

    .vs__selected {
      color: var(--colour--dropdown-item);

      &:not(:first-child) {
        display: none !important;
      }

      .vs__deselect {
        display: none !important;
      }
    }
  }
}
</style>
